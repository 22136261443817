<template>
    <div id="root">
        <img :src="getCurrentPic()" /> <!-- TODO loading per le wooden connections -->
        <!-- <div class="next-text">></div> -->
        <div v-if="has_multiple_images && checkForImage((current_burger) => ++current_burger)" @click="getPic((current_burger) => ++current_burger)" class="next"></div>
        <div v-if="has_multiple_images && checkForImage((current_burger) => --current_burger)" @click="getPic((current_burger) => --current_burger)" class="previous"></div>
    </div>
</template>

<script>

export default {
    name: 'MenuItem',
    data() {
        return {
            current_pic: {name: "", index: 0},
            has_multiple_images: (this.multiple_images != undefined) ? this.multiple_images : false
        }
    },
    props: ['hamburger_info', 'multiple_images'],
    methods: {
        getCurrentPic() {
            if (this.hamburger_info != 'default' && this.hamburger_info.name != this.current_pic['name']) {
                this.current_pic['name'] = this.hamburger_info.name;
                this.current_pic['index'] = 0
            }
            return (this.hamburger_info === "default") ? require('@/assets/background.jpg') : this.hamburger_info.photos[this.current_pic['index']]
        }, 
        getPic(func) {
            if (this.hamburger_info != "default") {
                for (let i = this.current_pic['index']; i < this.hamburger_info.photos.length; i++) {
                        this.current_pic['index'] = func(this.current_pic['index']);
                        return this.hamburger_info.photos[this.current_pic['index']];
                }
            }
        },
        checkForImage(func) {
            if (this.hamburger_info != "default") {
                for (let i = this.current_pic['index']; i < this.hamburger_info.photos.length; i++) {
                    return this.hamburger_info.photos[func(i)] != undefined;
                }
            }
            return false;
        }
    }
}
</script>


<style scoped>
    * {
        height: auto;
    }
    #root {
        color: #fff;
        position: relative;
        text-align: center;
    }
    /* .next-text {
        position: absolute;
        right: 4%;
        top: 45%;
        font-size: 40px;
        z-index: 2;
        cursor: pointer;
    } */
    .next {
        /* z-index: 1; */
        position: absolute;
        right: 0px;
        top: 0px;
        color: #000;
        height: 92%;
        width: 10%;
        background-color: rgb(49, 43, 43);
        opacity: .2;
        cursor: pointer;
    }
    .next:hover, .previous:hover {
        /* z-index: 1; */
        opacity: .6; 
    }
    .previous {
        position: absolute;
        left: 0px;
        top: 0px;
        color: #000;
        height: 92%;
        width: 10%;
        background-color: rgb(49, 43, 43);
        opacity: .2;
        cursor: pointer;
    }
    .next:active {
        opacity: .4; 
    }
    #root img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
    /* img {
        max-width: 100%;
        max-height: 100%;
    } */
    /* img {
        background: transparent url('http://thinkfuture.com/wp-content/uploads/2013/10/loading_spinner.gif') center no-repeat;
    } */
    @media screen and (max-width: 1360px) {
        .next, .previous {
            height: 98%;
        }
    }
</style>