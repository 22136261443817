<template>
    <div id="container">
        <div class="form-container">
            <h1 class="title">Per ogni domanda o dubbio non esitare a scriverci!</h1>
            <div class="form-container-inner">
               <form name="contact" method="POST" data-netlify="true">
                <input type="text" name="field1" placeholder="Nome" v-model="contactName" autocomplete="off"/>
                <input type="email" name="field2" placeholder="Email" v-model="contactEmail" autocomplete="off"/>
                <input type="checkbox" name="contact_me_by_email_only" style="display:none !important" v-model="emailOnlyCheck" @click="showshit">
                <textarea ref="area" id="message" @keydown="resizeArea('message')" placeholder="Messaggio" v-model="contactMessage"></textarea>
                <input type="button" value="Invia email" @click="sendMessage" />
            </form>
            </div>
        </div>
        <!-- <div class="v-separator" /> -->
        <div class="contacts-container">
            <!-- <div class="phone-contact"> -->
                <img class="icon" src="@/assets/icons/phone-icon.png" />
                <h3>380 182 6485</h3>
                <img class="icon" src="@/assets/icons/email-icon.png" />
                <h3>bottegadellhamburger@gmail.com</h3>
                <img class="address-icon" src="@/assets/icons/address-icon.png" />
                <h3 style="text-align:center;">Piazza Risorgimento, 13, 24128 Bergamo BG</h3>
                 <a href="https://www.instagram.com/bottegadellhamburger/" target="_blank"><img class="insta-logo social-logo" src="@/assets/insta_logo.png"></a>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contatti',
    data() {
        return {
            contactName: '',
            contactEmail: '',
            contactMessage: '',
            emailOnlyCheck: false
        }
    },
    methods: {
        checkForm() {
            return this.contactName != '' && this.contactEmail != '' && this.contactMessage != '' && !this.emailOnlyCheck
        },
        sendMessage() {
            if (this.checkForm())
                this.triggerSendMessage();
            else
                alert('Devi compilare tutti i campi');
                // console.log('You need to fill all input forms')
        },
        async triggerSendMessage() {
            try {
                const axios = require('axios').default;
                const response = await axios.post('/.netlify/functions/send-contact-email', {
                contactName: this.contactName,
                contactEmail: this.contactEmail,
                message: this.contactMessage
                })
                // this.messages.push({ type: 'success', text: response })
                // this.resetForm()
                alert('Messaggio inviato!');
                // console.log(response)
          } catch (error) {
                // this.messages.push({ type: 'error', text: error.response.data })
                // console.log(error)
          }
        },
        resizeArea(id) {
            let area = document.getElementById(id)
            area.style.height = 'auto';
            area.style.height = area.scrollHeight+'px';
        }
    }
}
</script>

<style scoped>
    /* .v-separator {
        height: 80%;
        border: 1px solid rgb(75, 75, 75);
    } */
    * {
        height: auto;
        overflow: auto;
    }
    .social-logo {
        width: 30px;
        height: 30px;
        padding: 1.5em;
    }
    #container {
        color: #F2F2ED;
        display: flex;
        flex-flow: column wrap;
        overflow: auto;
        justify-content: space-evenly;
        align-items: center;
        height: 90vh;
        /* background-image: url(../assets/background.jpg);
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat; */
        /* background-color: rgb(28, 31, 32); */
        /* background-color: rgb(202, 142, 78); */
    }
    h1 {
        font-weight: 600;
        font-size: 36px;
    }
    .title {
        /* margin-bottom: 1em; */
        text-align: center;
        margin-bottom: 2.5em;
    }
    .contacts-container {
        /* width: 300px; */
        /* height: 40vh; */
        /* border: 1px solid rgb(114, 114, 114); */
        /* background-color: rgb(24, 26, 27); */
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: 3em;
        /* padding-bottom: 3.2em; */
    }
    .form-container{
        font-family: 'Open Sans Condensed', arial, sans;
        /* width: 500px; */
        padding: 30px;
        padding: 30px;
        /* background-color: rgb(24,26,27); */
        /* margin: 50px auto; */
        /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.22);
        -webkit-box-shadow:  0px 0px 15px rgba(0, 0, 0, 0.22); */
        /* border: 1px solid rgb(114, 114, 114); */
    }
    .form-container h2{
        background: #4D4D4D;
        text-transform: uppercase;
        font-family: 'Open Sans Condensed', sans-serif;
        color: #797979;
        font-size: 18px;
        font-weight: 100;
        padding: 20px;
        margin: -30px -30px 30px -30px;
        margin-bottom: 4em;
    }
    .form-container input[type="text"],
    .form-container input[type="date"],
    .form-container input[type="datetime"],
    .form-container input[type="email"],
    .form-container input[type="number"],
    .form-container input[type="search"],
    .form-container input[type="time"],
    .form-container input[type="url"],
    .form-container input[type="password"],
    .form-container textarea,
    .form-container select 
    {
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        outline: none;
        display: inline-block;
        color: #fff;
        padding: 7px;
        border: none;
        border-bottom: 1px solid #ddd;
        background: transparent;
        margin-bottom: 10px;
        font: 16px Arial, Helvetica, sans-serif;
        height: 45px;
    }
    .form-container textarea{
        resize:none;
        overflow: hidden;
    }
    .form-container input[type="text"],
    .form-container input[type="email"]{
        margin-bottom: 3em;
    }
    .form-container input[type="button"], 
    .form-container input[type="submit"]{
        -moz-box-shadow: inset 0px 1px 0px 0px #45D6D6;
        -webkit-box-shadow: inset 0px 1px 0px 0px #45D6D6;
        box-shadow: inset 0px 1px 0px 0px #45D6D6;
        background-color: #2CBBBB;
        border: 1px solid #27A0A0;
        display: inline-block;
        cursor: pointer;
        color: #FFFFFF;
        font-family: 'Open Sans Condensed', sans-serif;
        font-size: 14px;
        padding: 8px 18px;
        text-decoration: none;
        text-transform: uppercase;
    }
    .form-container input[type="button"]:hover, 
    .form-container input[type="submit"]:hover {
        background:linear-gradient(to bottom, #34CACA 5%, #30C9C9 100%);
        background-color:#34CACA;
    }

    .icon {
        width: 45px;
        height: 45px;
        padding: 1.5em;
    }
    .address-icon {
        width: 35px;
        height: 55px;
        padding: 1.5em;
    }

    @media screen and (min-width:1280px) {
        .form-container {
        }
        .title {
        }
        .form-container input[type="text"],
        .form-container input[type="date"],
        .form-container input[type="datetime"],
        .form-container input[type="email"],
        .form-container input[type="number"],
        .form-container input[type="search"],
        .form-container input[type="time"],
        .form-container input[type="url"],
        .form-container input[type="password"],
        .form-container textarea,
        .form-container select 
        {
            margin-left: 2em;
            width: 90%;
        }
        .form-container input[type="button"], 
        .form-container input[type="submit"]{
            margin-left: 2.3em;
        }
    }
    @media screen and (max-width:1280px) {
        .v-separator {
            display: none;
        }
        .form-container input[type="text"],
        .form-container input[type="date"],
        .form-container input[type="datetime"],
        .form-container input[type="email"],
        .form-container input[type="number"],
        .form-container input[type="search"],
        .form-container input[type="time"],
        .form-container input[type="url"],
        .form-container input[type="password"],
        .form-container textarea,
        .form-container select 
        {
            width: 100%;
        }
    }
    @media screen and (max-height:875px) {
        #container {
            flex-flow: row wrap;
            height: 84vh;
        }
        .contacts-container {
            flex-flow: column wrap;
            padding: 0px;
        }
    }
    @media screen and (max-width:947px) {
        #container {
            flex-flow: row wrap;
        }
        .contacts-container {
            flex-flow: column wrap;
            padding: 0px;
        }
    }
    @media screen and (max-width:400px) {
        #container {
            height: 70vh;
        }
    }
    @media screen and (min-width: 822px) {
        .social-logo {
            display: none;
        }
    }
</style>