<template>
    <div id="container">
        <div class="whatdo">
            <h2>Seleziona un hamburger dal menu ~></h2>
            <h4 class="menu-download">Scarica il menu in pdf <a class="download-button" href="../../menu.pdf" download='menu.pdf'>QUI</a></h4>
        </div>
        <div class="v-separator" />
        <div class="menu-items-container">
            <div class="menu-item" v-for="hamburger in hamburger_infos" :key="hamburger.name" @click="updateSelected(hamburger)">
                <div class="hamburger-infos">
                    <a href="#" @click="selected_burger=hamburger">
                        <h2 class="name">{{hamburger.name}}</h2>
                    </a>
                        <h1 class="price">{{hamburger.price}}</h1>
                </div>
            </div>
        </div>
        <div class="v-separator" />
        <div>
            <h1 style="text-align:center; padding-bottom: 1rem;">{{getCurrentlySelected(selected_burger).name}}</h1>
            <div class="menu-presentation">
                <MenuItem :hamburger_info="getCurrentlySelected(selected_burger)" :multiple_images='true'/>
            </div>
            <h2 class="burger-additional-infos">{{getInfo(getCurrentlySelected(selected_burger), (info) => info.ingredients.join(' ~ '))}}</h2>
        </div>
    </div>
    <div id="container-low-res"> <!-- TODO meby v-if per non caricarlo nemmeno -->
        <div id="low-res">
            <div class="menu-item-low-res" v-for="hamburger in hamburger_infos" :key="hamburger.name">
                <MenuItem class="hamburger-img-low-res" :hamburger_info='hamburger' :multiple_images='true'/>
                <div class="hamburger-infos-low-res">
                    <h1 class="name">{{hamburger.name}}</h1>
                    <h1 class="price">{{hamburger.price}}</h1>
                    <h2 class="burger-additional-infos-low-res">{{hamburger.ingredients.join(' ~ ')}}</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
export default {
    name: 'Menu',
    components: {
      MenuItem
    },
    props: ['last_selected_burger'],
    data() {
        return {
            hamburger_infos: [
                    {name: 'Magico', price: '9€', ingredients: ['Hamburger 200gr', 'braciere', 'insalata', 'pomodoro', 'bacon', 'cetrioli', 'BBQ'], visible: false, photos: [require('@/assets/hamburgers/magico1.jpg'), require('@/assets/hamburgers/magico2.jpg'), require('@/assets/hamburgers/magico3.jpg'), require('@/assets/hamburgers/magico4.jpg')]},
                    {name: 'Peperonata', price: '9€', ingredients: ['Hamburger 200gr', 'braciere', 'peperoni e cipolle caramellate', 'scaglie di grana', 'bacon', 'maionese', 'ketchup'], visible: false, photos: [require('@/assets/hamburgers/peperonata1.jpg'), require('@/assets/hamburgers/peperonata2.jpg'), require('@/assets/hamburgers/peperonata3.jpg')]},
                    {name: 'Dolomiti', price: '10€', ingredients: ['Hamburger 200gr', 'brie', 'songino', 'speck', 'noci', 'salsa cocktail'], visible: false, photos: [require('@/assets/hamburgers/dolomiti1.jpg'),require('@/assets/hamburgers/dolomiti2.jpg'),require('@/assets/hamburgers/dolomiti3.jpg'),require('@/assets/hamburgers/dolomiti4.jpg')]},
                    {name: 'Fiammata', price: '9€', ingredients: ['Hamburger 200gr', 'caciocavallo', 'melanzane alla griglia', 'cipolle caramellate', 'nduja', 'salsa piccante'], visible: false, photos: [require('@/assets/hamburgers/fiammata1.jpg'),require('@/assets/hamburgers/fiammata2.jpg'),require('@/assets/hamburgers/fiammata3.jpg')]},
                    {name: 'Mexicano', price: '8€', ingredients: ['Straccetti di pollo alla griglia circa 200gr', 'straccetti di formaggella', 'peperoni e cipolle caramellate', 'salsa di avocado', 'salsa piccante'], visible: false, photos: [require('@/assets/hamburgers/messicano1.jpg'),require('@/assets/hamburgers/messicano2.jpg'),require('@/assets/hamburgers/messicano3.jpg')]},
                    {name: 'Cheeseburger', price: '8€', ingredients: ['Hamburger 200gr', 'braciere','ketchup','maionese'], visible: false, photos: [require('@/assets/nopic.png')]},
                    {name: 'Fantasia', price: '8€', ingredients: ['Hamburger 200gr', 'braciere','insalata','pomodoro','ketchup','maionese'], visible: false, photos: [require('@/assets/nopic.png')]},
                    {name: 'Sfizioso', price: '9€', ingredients: ['Hamburger 200gr', 'mozzarella di bufala','melanzane grigliate','pomodoro','basilico', 'glassa balsamica'], visible: false, photos: [require('@/assets/nopic.png')]},
                    {name: 'Delizia', price: '10€', ingredients: ['Hamburger 200gr', 'braciere','prosciutto cotto','insalata','funghi', 'maionese'], visible: false, photos: [require('@/assets/nopic.png')]},
                    {name: 'Bgburger', price: '10€', ingredients: ['Salamburger 200gr', 'insalata','branzi','pancetta','composta di fichi'], visible: false, photos: [require('@/assets/hamburgers/bgburger.jpeg')]},
                    {name: 'Intrecciata', price: '8€', ingredients: ['Hamburger di fassone 200gr', 'crescenza','pomodori secchi','rucola','prosciutto crudo di Parma', 'glassa balsamica'], visible: false, photos: [require('@/assets/hamburgers/intrecciata1.jpg')]},
                    {name: 'Nostalgico', price: '9€', ingredients: ['Hamburger di fassone 200gr', 'caccio','bacon','insalata','occhio di bue', 'scaglie di grana'], visible: false, photos: [require('@/assets/nopic.png')]},
                    {name: 'Leggendario', price: '12€', ingredients: ['Hamburger 300gr', 'formaggella begamasca','insalata','lardo','composta di cipolle'], visible: false, photos: [require('@/assets/hamburgers/leggendario1.jpg')]},
                    {name: 'Anagrumi', price: '10€', ingredients: ['Straccetti di anatra aromatizzato agli agrumi', 'circa 200gr insalata', 'fortuna', 'pomodori secchi', 'funghi', 'maionese'], visible: false, photos: [require('@/assets/nopic.png')]},
                    {name: 'Vegan bun', price: '7€', ingredients: ['Verdure alla griglia', 'cipolle e peproni caramellati', 'salsa di avocado', 'rucola'], visible: false, photos: [require('@/assets/nopic.png')]},
                    {name: 'Cheeseburger baby', price: '5€', ingredients: ['Hamburger 120gr','braciere', 'maionese', 'ketchup'], visible: false, photos: [require('@/assets/nopic.png')]},
                    {name: 'Tricolore baby', price: '5€', ingredients: ['Hamburger 120gr','braciere', 'insalata', 'pomodoro', 'maionese', 'ketchup'], visible: false, photos: [require('@/assets/nopic.png')]}
                ],
            //     items_classico_insalata: [
            //         {itemType: 'insalata', name: 'Insalatona Golosa', price: '7€', ingredients: ['Insalata', 'pomodori', 'tonno', 'mozzarella di bufala', 'mais', 'olive'], visible: false},
            //         {itemType: 'insalata', name: 'Insalatona Contadina', price: '7€', ingredients: ['Insalata', 'cetrioli', 'pomodoro', 'straccetti di pollo', 'scaglie di grana', 'olive', 'crostini di pane', 'salsa tzatziki'], visible: false}
            //     ],
            //     items_classico_hotdog: [
            //         {itemType: 'hotdog', name: 'Hot dog 1', price: '4€', ingredients: ['Salamella qualità Gamba', 'insalata', 'ketchup', 'maionese'], visible: false},
            //         {itemType: 'hotdog', name: 'Hot dog 2', price: '5€', ingredients: ['Salamella qualità Gamba', 'verdure caramellate', 'braciere', 'maionese'], visible: false}
            //     ],
            //     items_classico_dolce: [
            //         {itemType: 'dolce', name: 'Crepes salata 1', price: '6€', ingredients: ['Fontina', 'prosciutto cotto', 'insalata', 'funghi', 'maionese'], visible: false},
            //         {itemType: 'dolce', name: 'Crepes salata 2', price: '6€', ingredients: ['Straccetti di formaggella', 'rucola', 'prosciutto crudo', 'pomodori secchi'], visible: false}
            //     ],
            selected_burger: "default"
        }
    },
    methods: {
        getInfo(info, infoFunc) {
            return (info != 'default') ? infoFunc(info) : ''
        },
        updateSelected(current) {
            this.$emit('last_selected_burger', current)
        },
        getCurrentlySelected(current) {
            return (this.last_selected_burger != "default") ? this.last_selected_burger : current
        }
    },
    computed: {
        limitShownItems() {
            return this.hamuburger_infos.slice(0, this.max_items_shown)
        }
    }
}

</script>

<style scoped>
    .whatdo {
        display: flex;
        flex-direction: column;
    }
    .menu-download {
        margin-top: 1rem;
    }
    .download-button {
        /* text-decoration: underline; */
        color: rgb(180, 162, 162);
    }
    .hamburger-infos{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .price {
        margin-left: 5em;
    }
    * {
        height: auto;
    }
    a {
        text-decoration: none;
        color: #F2F2ED;
    }
    h1 {
        font-size: 20px
    }
    h2 {
        font-size: 16px;
    }
    h4 {
        font-size: 12px;
    }
    .name:hover {
        text-decoration: underline;
    }
    .burger-additional-infos {
        padding-top: 1em;
        text-align: center;
        /* width: 750px; */
        width: 100%;
    }
    #container {
        color: #F2F2ED;
        display: flex;
        flex-flow: row nowrap;
        overflow: auto;
        justify-content: space-around;
        align-items: center;
        height: 84vh;
    }
    .menu-items-container {
        flex-direction: column;
        overflow: auto;
        /* border-bottom: 3px solid rgba(187, 157, 169, 0.397); */
    }
    .menu-item {
        margin-top: 1em;
    }
    @media screen and (max-height: 700px) {
        .menu-item {
            margin-top: .2em;
        }
    }
    .v-separator {
        height: 80%;
        border: 1px solid rgb(75, 75, 75);
    }
    .menu-presentation {        
        width: 1000px;
        /* height: 450px; */
        max-height: 450px;
        border: 1px solid #fff;
        margin: 0 auto;
    }
    #container-low-res > * {
        display: none;
    }
    @media screen and (max-width: 1360px) {
        .v-separator {
            display: none;
        }
        #container {display: none;}
        #container > * {display: none;}
        #container-low-res {
            overflow: auto;
            height: 80vh;
            color: #F2F2ED;
            /* text-align: center; */
        }
        #low-res {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-evenly;
            padding: 1.5rem;
        }
        .menu-item-low-res {
            margin-top: 1.5rem;
            width: 500px;
            max-height: auto;
        }
        .hamburger-infos-low-res{
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            background-color: rgb(11, 11, 11);
            padding: .5rem 1rem .5rem 1rem;
        }
        .name:hover {
            text-decoration: none;
        }
    }
    @media screen and (max-width:1680px) {
        .menu-presentation {   
            width: 800px;
            max-height: 350px;
            margin: 0 auto;
        }
    }
</style>