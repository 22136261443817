<template>
  <transition name="fade" v-for="page in menu_items" :key="page" >
    <component :is="page" v-bind:orari="orari" v-if="current_page == page" @last_selected_burger="changeBurger" :last_selected_burger="last_selected_burger" />
    <!-- TODO props per component specifico, ora mette le stesse props a tutti -->
  </transition>
</template>

<script>
import Home from './Home.vue'
import Menu from './Menu.vue'
import Contatti from './Contatti.vue'
// import Admin from './Admin.vue'

export default {
  name: 'MainContent',
  props: ['menu_items', 'current_page'],
  components: {
      Home,
      Menu,
      Contatti//,
      // Admin
  },
  data() {
      return {
          orari: [
              {day: 'Lunedi', closed: true},
              {day: 'Martedi', opening_morning: '12:00 - 15:00', opening_evening: '18:30 - 22:00'},
              {day: 'Mercoledi', opening_morning: '12:00 - 15:00', opening_evening: '18:30 - 22:00'},
              {day: 'Giovedi', opening_morning: '12:00 - 15:00', opening_evening: '18:30 - 22:00'},
              {day: 'Venerdi', opening_morning: '12:00 - 15:00', opening_evening: '18:30 - 23:00'},
              {day: 'Sabato', opening_morning: '12:00 - 15:00', opening_evening: '18:30 - 23:00'},
              {day: 'Domenica', opening_morning: '12:00 - 15:00', opening_evening: '18:30 - 22:00'}
            ],
          last_selected_burger: "default"
      }
  },
  methods: {
    changeBurger(item) {
      this.last_selected_burger = item
    }
  }
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }
    .fade-enter-from, .fade-leave-to {
      opacity: 0;
    }
</style>
