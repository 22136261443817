<template>
    <!-- <div id="container"> -->
        <!-- <img class="search-img" src="@/assets/search.png" @click="search()"/> -->
        <div>
            <label for="inp" class="inp">
                <input type="text" id="inp" placeholder="&nbsp;">
                <span class="label">Cerca hamburger</span>
                <span class="focus-bg"></span>
            </label>
        </div>
    <!-- </div> -->
</template>

<script>
export default {
  name: 'Search',
  data() {
      return {
          show_search_modal: false
      }
  },
  methods: {
      search() {
          this.show_search_modal = !this.show_search_modal
      },
      fuck() {
          // console.log('fuck')
      }
  }
}
</script>

<style scoped>
#container {
    display: flex;
    flex-direction: row;
}
.search-img{
    width:30px;
    height: 30px;
}
.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  border-radius: 3px;
  overflow: hidden;
}
.inp .label {
  position: absolute;
  top: 8px;
  left: 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  pointer-events: none;
}
.inp .focus-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
}
.inp input {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 16px 12px 0 12px;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.02);
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.3);
  color: rgb(255, 255, 255);
  transition: all 0.15s ease;
}
.inp input:hover {
  background: rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5);
}
.inp input:not(:-moz-placeholder-shown) + .label {
  color: rgba(255, 255, 255, 0.5);
  transform: translate3d(0, -24px, 0) scale(0.75);
}
.inp input:not(:-ms-input-placeholder) + .label {
  color: rgba(255, 255, 255, 0.5);
  transform: translate3d(0, -24px, 0) scale(0.75);
}
.inp input:not(:placeholder-shown) + .label {
  color: rgba(255, 255, 255, 0.5);
  transform: translate3d(0, -24px, 0) scale(0.75);
}
.inp input:focus {
  background: rgba(255, 255, 255, 0.02);
  outline: none;
  box-shadow: inset 0 -2px 0 #ffffff;
}
.inp input:focus + .label {
  color: #ffffff;
  transform: translate3d(0, -40px, 0) scale(0.75);
}
.inp input:focus + .label + .focus-bg {
  transform: scaleX(1);
  transition: all 0.1s ease;
}
</style>