import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

// console.log(app.config);

app.config.warnHandler = function(msg, vm, trace) {
    // `trace` is the component hierarchy trace
  }

app.mount('#app')
