<template>
  <WebMenu @selected_page="change_page" :menu_items="menu_items" />
  <MainContent :current_page="current_page" :menu_items="menu_items"/>
</template>

<script>
import WebMenu from './components/WebMenu.vue'
import MainContent from './components/MainContent.vue'

export default {
  name: 'App',
  components: {
    WebMenu,
    MainContent
  },
  data() {
    return {
      menu_items: ["Home", "Menu", "Contatti"], //"Admin"
      current_page: 'Home'
    }
  },
  methods: {
    change_page(item) {
      this.current_page = item
    }
  }
}
</script>

<style>
  html,body {
      background-color: rgb(14, 14, 14);
  }
  * {
    margin: 0px;
    padding: 0px;
    /* height: 100%; */
  }
  @media screen and (min-height: 640px) {
    * {
      overflow: hidden;
    }
  }
  ::-webkit-scrollbar {
   width: 20px;
  }

  ::-webkit-scrollbar-track {
   border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background:rgb(19, 19, 19); 
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background:rgb(54, 54, 54); 
  }
  #app {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  }
  .separator-h {
    height: 1px;
    background-color: #9A9A9A;
  }
</style>
