<template>
    <div id="container">
        <div class="orari-container">
            <!-- <div class="separator-h" /> -->
            <h1>Orari Apertura</h1>
            <div class="orari-table">
                <table>
                        <tr v-for="orario in orari" :key="orario">
                            <td>{{orario.day}}</td>
                            <td>{{checkClosed(orario, orario.opening_morning)}}</td>
                            <td>{{checkClosed(orario, orario.opening_evening)}}</td>
                        </tr>
                </table>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    name: "Home",
    props: ['orari'],
    methods: {
        checkClosed(orario, orario_day) {
            return (orario.closed != undefined && orario.closed) ? "Chiuso" : orario_day
        }
    }
}
</script>

<style scoped>
    #container * {
        height: auto;
        font-family: Arial, Helvetica, sans-serif;
    }
    .orari-container, .orari-table {
        padding: 1em;
        /* display: flex;
        flex-direction: column; */
    }
    @media screen and (min-width: 1301px) {
        .orari-container, .orari-table {
            width: 50%;
            margin: auto;
        }
    }
    h1 {
        margin-top: 1em;
        font-size: 5.5em;
        text-align: center;
    }
    table {
        padding-top: 1em;
        margin: 0 auto;
    }
    td {
        padding: 0 1em .3em 0;
        font-size: 1.3em;
        white-space: nowrap;
        font-weight: bold;
    }
    @media screen and (max-width: 1580px) {
        td {
            font-size: 1em;
            padding-bottom: 1em;
        }
        h1 {
            font-size: 3.5em;
            margin-top: 0;
        }
    }
    @media screen and (max-width: 800px) {
        td {
            white-space: pre-wrap;
            /* padding-right: 0; */
            font-size: .8em;
        }
    }
    #container {
        color: #F2F2ED;
        height: 100vh;
        background-image: url(../assets/background.jpg);
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        height: 83.9vh;
    }
</style>
