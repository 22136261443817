<template>
  <div id="container">
    <div class="bottega-container">
      <img class="bottega-logo" src="@/assets/bottega.svg">
    </div>
    <ul class="menu-items">
      <li v-for="item in menu_items" :key="item">
        <a href="#">
          <div class="menu-item-container">
            <span @click="change_current_page(item)" class="menu-item-name">{{ item }}</span>
            <span v-if="current_page == item" class="menu-item-underline"></span>
          </div>
        </a>
      </li>
    </ul>
    <div class="menu-social">
      <a href="https://www.instagram.com/bottegadellhamburger/" target="_blank"><img class="insta-logo social-logo" src="@/assets/insta_logo.png"></a>
      <!-- <a href="#"><img class="face-logo social-logo" src="@/assets/face_logo.png"></a> -->
    </div>
    <!-- <div class="search">
      <Search />
    </div> -->
  </div>
  <div class="separator-h" />
</template>

<script>
import Search from './Search.vue'

export default {
  name: 'WebMenu',
  components: {
    Search
  },
  props: ['menu_items'],
  data() {
    return {
      current_page: 'Home'
    }
  },
  methods: {
    change_current_page(item_selected) {
      this.current_page = item_selected
      this.$emit('selected_page', item_selected)
    }
  }
}
</script>

<style scoped>
  * {
    height: auto;
  }
  #container {
    background-color: #080909;
    color: #fff;
    display: flex;
    flex-direction: row;
    padding: .7em;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  ul, li {
    list-style-type: none;
  }
  .menu-items {
    display: flex;
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    .menu-items {
      display: block;
    }
  }
  .menu-items a {
    text-decoration: none;
    color: #fff;
  }
  li {
    padding: 0 1.5em .3em 1.5em;
  }
  .menu-item-container {
    display: flex;
    flex-direction: column;
  }
  .menu-item-underline {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .bottega-container {
    margin-right: 3%;
  }
  .bottega-logo {
    width: 334px;
    height: 129px;
  }
  .menu-social {
    /* display: flex; */
  }
  .insta-logo {
    padding-right: 1em;
  }
  .social-logo {
    width: 30px;
    height: 30px;
  }
  /* .search-logo {
    width: 30px;
    height: 30px;
  } */
  .search {
    margin-left: 5%;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 980px) {
      .bottega-logo {
        max-width: 200px;
        max-height: 100px;
      }
  }
  @media screen and (min-width:628px) and (max-width:1279px) {
    .search {
      display: none;
    }
  }
  @media screen and (max-width: 822px) {
    li {
      padding: 0 1em .3em 1em;
    }
    .social-logo {
      display: none;
    }
  }
  /* @media screen and (min-width: 646px) and (max-width: 734px) {
      .bottega-logo {
        max-width: 240px;
        max-height: 120px;
      }
  } */
</style>
